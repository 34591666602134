var render = function () {
  var _vm$product$_vm$produ, _vm$$v$product$_vm$pr, _vm$product$_vm$produ2, _vm$$v$product$_vm$pr2, _vm$product, _vm$product2, _vm$product3, _vm$product$_vm$produ3, _vm$$v$product$_vm$pr6, _vm$$v$product$_vm$pr7, _vm$$v$product$_vm$pr8, _vm$$v$product$_vm$pr9, _vm$$v$product$_vm$pr10, _vm$$v4, _vm$$v4$product$_vm$p, _vm$$v4$product$_vm$p2, _vm$$v$product$_vm$pr11, _vm$$v$product$_vm$pr12, _vm$$v$product$_vm$pr13, _vm$$v$product$_vm$pr14, _vm$$v5, _vm$$v5$product$_vm$p, _vm$$v5$product$_vm$p2, _vm$$v$product$_vm$pr15, _vm$$v$product$_vm$pr16, _vm$$v$product$_vm$pr17, _vm$$v$product$_vm$pr18, _vm$$v6, _vm$$v6$product$_vm$p, _vm$$v6$product$_vm$p2, _vm$product$_vm$produ4, _vm$product$_vm$produ5, _vm$$v$product$_vm$pr19, _vm$$v$product$_vm$pr20, _vm$product4, _vm$product5, _vm$product6, _vm$product7, _vm$product7$data, _vm$product7$data$ser, _vm$currentAvailableS, _vm$product8, _vm$product8$data, _vm$product8$data$ser, _vm$currentAvailableS2, _vm$product9, _vm$product9$data, _vm$product9$data$ser, _vm$currentAvailableS3, _vm$product$data, _vm$product$data$seri, _vm$$v7, _vm$$v7$product, _vm$$v7$product$data, _vm$$v7$product$data$, _vm$$v8, _vm$$v8$product, _vm$$v8$product$data, _vm$$v8$product$data$, _vm$$v9, _vm$$v9$product, _vm$$v9$product$data, _vm$$v9$product$data$, _vm$product$data2, _vm$product$data2$ser, _vm$$v10, _vm$$v10$product, _vm$$v10$product$data, _vm$$v10$product$data2, _vm$product$data3, _vm$product$data3$ser, _vm$$v11, _vm$$v11$product, _vm$$v11$product$data, _vm$$v11$product$data2, _vm$product$data4, _vm$product$data4$ser, _vm$$v12, _vm$$v12$product, _vm$$v12$product$data, _vm$$v12$product$data2, _vm$$v13, _vm$$v13$product, _vm$$v13$product$data, _vm$$v13$product$data2, _vm$product$data5, _vm$product$data5$llc, _vm$$v14, _vm$$v14$product, _vm$$v14$product$data, _vm$$v14$product$data2, _vm$product$data6, _vm$product$data6$llc, _vm$$v15, _vm$$v15$product, _vm$$v15$product$data, _vm$$v15$product$data2, _vm$agreementTemplate, _vm$agreementTemplate2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-investor-form"
  }, [_c('div', {
    staticClass: "add-new-investor-form__tabs"
  }, [_c('div', {
    class: ['add-new-investor-form__tab', _vm.openedTab == 'contract' ? 'active' : ''],
    on: {
      "click": function click($event) {
        _vm.openedTab = 'contract';
      }
    }
  }, [_vm._v("Contract")]), _c('div', {
    class: ['add-new-investor-form__tab', _vm.openedTab == 'agreement' ? 'active' : '', !_vm.hasTemplateChosen ? 'disabled' : ''],
    on: {
      "click": _vm.openAgreementTab
    }
  }, [_vm._v("Agreement")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openedTab == 'contract',
      expression: "openedTab == 'contract'"
    }],
    staticClass: "add-new-investor-form__body"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    staticClass: "mb-3"
  }, [_vm._v(" Add new product ")]), !_vm.isEquity ? [_c('div', {
    staticClass: "content__group content__group--big"
  }, [_c('sygni-date-picker', {
    staticClass: "secondary",
    attrs: {
      "label": 'AGREEMENT DATE',
      "validation": _vm.$v.product.agreementDate
    },
    model: {
      value: _vm.product.agreementDate,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "agreementDate", $$v);
      },
      expression: "product.agreementDate"
    }
  }), _c('div', {
    staticClass: "investment-value-group"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'INVESTMENT VALUE',
      "validation": _vm.$v.product.investmentValue,
      "displayFormat": 'currency'
    },
    model: {
      value: _vm.product.investmentValue,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "investmentValue", $$v);
      },
      expression: "product.investmentValue"
    }
  }), _c('sygni-select', {
    attrs: {
      "options": _vm.usedCurrencies,
      "allowEmpty": false,
      "validation": _vm.$v.product.currency,
      "clearable": false
    },
    model: {
      value: _vm.product.currency,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "currency", $$v);
      },
      expression: "product.currency"
    }
  })], 1), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "container__group container__group--duration"
  }, [_c('sygni-radio', {
    staticClass: "secondary",
    attrs: {
      "label": 'FIXED REPAYMENT DAY',
      "radio-value": true,
      "group": 'payment'
    },
    model: {
      value: _vm.fixedReplacementDay,
      callback: function callback($$v) {
        _vm.fixedReplacementDay = $$v;
      },
      expression: "fixedReplacementDay"
    }
  }), _c('sygni-radio', {
    staticClass: "secondary",
    attrs: {
      "label": 'DURATION',
      "radio-value": false,
      "group": 'payment'
    },
    model: {
      value: _vm.fixedReplacementDay,
      callback: function callback($$v) {
        _vm.fixedReplacementDay = $$v;
      },
      expression: "fixedReplacementDay"
    }
  })], 1), _c('sygni-date-picker', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.fixedReplacementDay,
      expression: "fixedReplacementDay"
    }],
    ref: "fixedRepaymentDate",
    staticClass: "secondary",
    attrs: {
      "label": 'FIXED REPAYMENT DAY',
      "validation": _vm.$v.product[_vm.productDataName].fixedRepaymentDate
    },
    model: {
      value: _vm.product[_vm.productDataName].fixedRepaymentDate,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName], "fixedRepaymentDate", $$v);
      },
      expression: "product[productDataName].fixedRepaymentDate"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.fixedReplacementDay,
      expression: "!fixedReplacementDay"
    }],
    staticClass: "duration-group"
  }, [_c('sygni-select', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.fixedReplacementDay,
      expression: "!fixedReplacementDay"
    }],
    staticClass: "secondary",
    attrs: {
      "label": 'DURATION',
      "options": _vm.productSelects.duration,
      "placeholder": 'Choose',
      "value": (_vm$product$_vm$produ = _vm.product[_vm.productDataName].duration) === null || _vm$product$_vm$produ === void 0 ? void 0 : _vm$product$_vm$produ.type,
      "validation": (_vm$$v$product$_vm$pr = _vm.$v.product[_vm.productDataName].duration) === null || _vm$$v$product$_vm$pr === void 0 ? void 0 : _vm$$v$product$_vm$pr.type,
      "clearable": false
    },
    on: {
      "input": _vm.setDurationType
    }
  }), _c('sygni-input', {
    staticClass: "secondary duration-group__count",
    attrs: {
      "label": 'COUNT',
      "displayFormat": 'integer',
      "value": (_vm$product$_vm$produ2 = _vm.product[_vm.productDataName].duration) === null || _vm$product$_vm$produ2 === void 0 ? void 0 : _vm$product$_vm$produ2.count,
      "validation": (_vm$$v$product$_vm$pr2 = _vm.$v.product[_vm.productDataName].duration) === null || _vm$$v$product$_vm$pr2 === void 0 ? void 0 : _vm$$v$product$_vm$pr2.count
    },
    on: {
      "input": _vm.setDurationCount
    }
  })], 1)], 1), _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "label": 'INTEREST TYPE',
      "options": _vm.productSelects.interestType,
      "placeholder": 'Choose',
      "value": _vm.product[_vm.productDataName].interestType,
      "allowEmpty": false,
      "validation": _vm.$v.product[_vm.productDataName].interestType
    },
    on: {
      "input": _vm.setInterestType
    }
  }), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "container__group"
  }, [_c('sygni-toggle-switch', {
    staticClass: "secondary advanced-options-switch",
    attrs: {
      "label": 'ADVANCED OPTIONS'
    },
    model: {
      value: _vm.product[_vm.productDataName].enabledAdvancedOptions,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName], "enabledAdvancedOptions", $$v);
      },
      expression: "product[productDataName].enabledAdvancedOptions"
    }
  })], 1), !_vm.product[_vm.productDataName].enabledAdvancedOptions ? [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'INTEREST P.A.',
      "unit": '%',
      "value": _vm.product[_vm.productDataName].simpleOptions.interest,
      "displayFormat": "currency",
      "validation": _vm.$v.product[_vm.productDataName].simpleOptions.interest
    },
    on: {
      "input": _vm.setInterestPA,
      "blur": function blur($event) {
        var _vm$$v, _vm$$v$product$_vm$pr3, _vm$$v$product$_vm$pr4, _vm$$v$product$_vm$pr5;

        (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : (_vm$$v$product$_vm$pr3 = _vm$$v.product[_vm.productDataName]) === null || _vm$$v$product$_vm$pr3 === void 0 ? void 0 : (_vm$$v$product$_vm$pr4 = _vm$$v$product$_vm$pr3.simpleOptions) === null || _vm$$v$product$_vm$pr4 === void 0 ? void 0 : (_vm$$v$product$_vm$pr5 = _vm$$v$product$_vm$pr4.interest) === null || _vm$$v$product$_vm$pr5 === void 0 ? void 0 : _vm$$v$product$_vm$pr5.$touch();
      }
    }
  }), _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "label": 'INTEREST PAYMENT',
      "options": _vm.productSelects.interestPayment,
      "validation": _vm.$v.product[_vm.productDataName].simpleOptions.interestPayment
    },
    model: {
      value: _vm.product[_vm.productDataName].simpleOptions.interestPayment,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].simpleOptions, "interestPayment", $$v);
      },
      expression: "product[productDataName].simpleOptions.interestPayment"
    }
  })] : _vm._e(), _vm.product[_vm.productDataName].enabledAdvancedOptions ? _c('div', {
    staticClass: "advanced-options__container"
  }, [_c('div', {
    staticClass: "advanced-options__commissions"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'COMMISSION (P.A.)',
      "value": _vm.product[_vm.productDataName].advancedOptions.commission,
      "displayFormat": "currency",
      "validation": _vm.$v.product[_vm.productDataName].advancedOptions.commission
    },
    on: {
      "input": _vm.setCommissionPA,
      "blur": function blur($event) {
        var _vm$$v2, _vm$$v2$product$_vm$p, _vm$$v2$product$_vm$p2, _vm$$v2$product$_vm$p3;

        (_vm$$v2 = _vm.$v) === null || _vm$$v2 === void 0 ? void 0 : (_vm$$v2$product$_vm$p = _vm$$v2.product[_vm.productDataName]) === null || _vm$$v2$product$_vm$p === void 0 ? void 0 : (_vm$$v2$product$_vm$p2 = _vm$$v2$product$_vm$p.advancedOptions) === null || _vm$$v2$product$_vm$p2 === void 0 ? void 0 : (_vm$$v2$product$_vm$p3 = _vm$$v2$product$_vm$p2.commission) === null || _vm$$v2$product$_vm$p3 === void 0 ? void 0 : _vm$$v2$product$_vm$p3.$touch();
      }
    }
  }), _c('sygni-select', {
    staticClass: "secondary small",
    attrs: {
      "options": [{
        label: '%',
        value: 'percent'
      }, {
        label: (_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.currency,
        value: 'currency'
      }],
      "clearable": false,
      "placeholder": '',
      "validation": _vm.$v.product[_vm.productDataName].advancedOptions.commissionType
    },
    model: {
      value: _vm.product[_vm.productDataName].advancedOptions.commissionType,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].advancedOptions, "commissionType", $$v);
      },
      expression: "product[productDataName].advancedOptions.commissionType"
    }
  })], 1), _c('div', {
    staticClass: "advanced-options__commissions"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'MARGIN (P.A.)',
      "value": _vm.product[_vm.productDataName].advancedOptions.margin,
      "displayFormat": "currency",
      "validation": _vm.$v.product[_vm.productDataName].advancedOptions.margin
    },
    on: {
      "input": _vm.setMarginPA,
      "blur": function blur($event) {
        var _vm$$v3, _vm$$v3$product$_vm$p, _vm$$v3$product$_vm$p2, _vm$$v3$product$_vm$p3;

        (_vm$$v3 = _vm.$v) === null || _vm$$v3 === void 0 ? void 0 : (_vm$$v3$product$_vm$p = _vm$$v3.product[_vm.productDataName]) === null || _vm$$v3$product$_vm$p === void 0 ? void 0 : (_vm$$v3$product$_vm$p2 = _vm$$v3$product$_vm$p.advancedOptions) === null || _vm$$v3$product$_vm$p2 === void 0 ? void 0 : (_vm$$v3$product$_vm$p3 = _vm$$v3$product$_vm$p2.margin) === null || _vm$$v3$product$_vm$p3 === void 0 ? void 0 : _vm$$v3$product$_vm$p3.$touch();
      }
    }
  }), _c('sygni-select', {
    staticClass: "secondary small",
    attrs: {
      "options": [{
        label: '%',
        value: 'percent'
      }, {
        label: (_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2.currency,
        value: 'currency'
      }],
      "clearable": false,
      "placeholder": '',
      "validation": _vm.$v.product[_vm.productDataName].advancedOptions.marginType
    },
    model: {
      value: _vm.product[_vm.productDataName].advancedOptions.marginType,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].advancedOptions, "marginType", $$v);
      },
      expression: "product[productDataName].advancedOptions.marginType"
    }
  })], 1)]) : _vm._e(), _vm.product[_vm.productDataName].enabledAdvancedOptions ? _c('div', {
    staticClass: "advanced-options__container"
  }, [_c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.productSelects.interestPayment,
      "placeholder": 'Choose',
      "clearable": false,
      "label": 'COMMISSIONS PAYMENT',
      "validation": _vm.$v.product[_vm.productDataName].advancedOptions.commissionPayment
    },
    model: {
      value: _vm.product[_vm.productDataName].advancedOptions.commissionPayment,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].advancedOptions, "commissionPayment", $$v);
      },
      expression: "product[productDataName].advancedOptions.commissionPayment"
    }
  }), _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.productSelects.interestPayment,
      "clearable": false,
      "label": 'MARGIN PAYMENT',
      "placeholder": 'Choose',
      "validation": _vm.$v.product[_vm.productDataName].advancedOptions.marginPayment
    },
    model: {
      value: _vm.product[_vm.productDataName].advancedOptions.marginPayment,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].advancedOptions, "marginPayment", $$v);
      },
      expression: "product[productDataName].advancedOptions.marginPayment"
    }
  })], 1) : _vm._e()], 2), _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "label": 'INTEREST CALCULATION METHOD',
      "options": _vm.productSelects.interestCalculationMethod,
      "placeholder": 'Choose',
      "validation": _vm.$v.product[_vm.productDataName].interestCalculationMethod
    },
    model: {
      value: _vm.product[_vm.productDataName].interestCalculationMethod,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName], "interestCalculationMethod", $$v);
      },
      expression: "product[productDataName].interestCalculationMethod"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'INTEREST PAID WITHIN',
      "validation": _vm.$v.product[_vm.productDataName].interestPaidWithin,
      "placeholder": "0",
      "displayFormat": "integer",
      "value": _vm.product[_vm.productDataName].interestPaidWithin
    },
    on: {
      "input": _vm.setInterestPaidWithin
    }
  }), _c('sygni-checkbox', {
    staticClass: "secondary",
    attrs: {
      "label": "Capital Gains Tax"
    },
    model: {
      value: _vm.product.capitalGainsTax,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "capitalGainsTax", $$v);
      },
      expression: "product.capitalGainsTax"
    }
  }), _c('sygni-checkbox', {
    staticClass: "secondary",
    attrs: {
      "label": "CLAT Tax"
    },
    model: {
      value: _vm.product.clatTax,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "clatTax", $$v);
      },
      expression: "product.clatTax"
    }
  })], 1), _c('div', {
    staticClass: "content__group content__group--small"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Details ")]), _c('sygni-input', {
    attrs: {
      "label": 'PRODUCT TYPE',
      "value": _vm.product.type === 'BILL_AGREEMENT' ? 'Bill agreement' : 'Loan',
      "disabled": true,
      "validation": _vm.$v.product.type
    }
  }), _vm.isMarketplaceAdmin || _vm.isMarketplaceHead ? _c('sygni-input', {
    attrs: {
      "label": 'PRODUCT CODE',
      "disabled": _vm.disableProductCode,
      "validation": _vm.$v.product.code
    },
    model: {
      value: _vm.product.code,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "code", $$v);
      },
      expression: "product.code"
    }
  }) : _vm._e(), _c('sygni-checkbox', {
    staticClass: "secondary autogenerate-checkbox",
    attrs: {
      "label": "AUTOGENERATE PRODUCT CODE"
    },
    model: {
      value: _vm.product.autogeneratedCode,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "autogeneratedCode", $$v);
      },
      expression: "product.autogeneratedCode"
    }
  }), _vm.isMarketplaceAdmin || _vm.isMarketplaceHead ? _c('sygni-input', {
    attrs: {
      "label": 'PRODUCT NAME',
      "validation": _vm.$v.product.name
    },
    model: {
      value: _vm.product.name,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "name", $$v);
      },
      expression: "product.name"
    }
  }) : _vm._e(), ((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.type) === 'BILL_AGREEMENT' && (_vm.isMarketplaceAdmin || _vm.isMarketplaceHead) ? _c('sygni-input', {
    attrs: {
      "label": 'BILL NUMBER',
      "value": (_vm$product$_vm$produ3 = _vm.product[_vm.productDataName]) === null || _vm$product$_vm$produ3 === void 0 ? void 0 : _vm$product$_vm$produ3.billNumber,
      "validation": (_vm$$v$product$_vm$pr6 = _vm.$v.product[_vm.productDataName]) === null || _vm$$v$product$_vm$pr6 === void 0 ? void 0 : _vm$$v$product$_vm$pr6.billNumber
    },
    on: {
      "input": _vm.setBillNumber
    }
  }) : _vm._e(), _c('sygni-select', {
    attrs: {
      "options": _vm.attachmentOptions,
      "label": "AGREEMENT TEMPLATE",
      "selectOnlyOption": true,
      "validation": _vm.$v.product.agreementTemplateId
    },
    model: {
      value: _vm.product.agreementTemplateId,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "agreementTemplateId", $$v);
      },
      expression: "product.agreementTemplateId"
    }
  }), _vm.isMarketplaceAdmin || _vm.isMarketplaceHead ? [_c('div', {
    staticClass: "title title--bank"
  }, [_vm._v(" Bank accounts ")]), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "content__bank-account"
  }, [_c('sygni-input', {
    attrs: {
      "displayCheck": _vm.initialPaymentBankAccountDisplayCheck,
      "label": 'INITIAL PAYMENT',
      "mask": _vm.initialPaymentBankAccountDisplayFormat,
      "validation": (_vm$$v$product$_vm$pr7 = _vm.$v.product[_vm.productDataName]) === null || _vm$$v$product$_vm$pr7 === void 0 ? void 0 : (_vm$$v$product$_vm$pr8 = _vm$$v$product$_vm$pr7.initialPaymentBankAccount) === null || _vm$$v$product$_vm$pr8 === void 0 ? void 0 : _vm$$v$product$_vm$pr8.account
    },
    on: {
      "input": _vm.handleInitialPaymentBankAccountKeyEvent,
      "blur": _vm.overwriteInitialPaymentBankAccount
    },
    model: {
      value: _vm.product[_vm.productDataName].initialPaymentBankAccount.account,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].initialPaymentBankAccount, "account", $$v);
      },
      expression: "product[productDataName].initialPaymentBankAccount.account"
    }
  }), _c('sygni-select', {
    attrs: {
      "label": 'COUNTRY',
      "allowEmpty": false,
      "optionsLabel": "value",
      "options": _vm.globalSelects.countries,
      "searchable": true
    },
    model: {
      value: _vm.product[_vm.productDataName].initialPaymentBankAccount.country,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].initialPaymentBankAccount, "country", $$v);
      },
      expression: "product[productDataName].initialPaymentBankAccount.country"
    }
  })], 1), _c('sygni-input', {
    attrs: {
      "label": "SWIFT CODE",
      "validation": (_vm$$v$product$_vm$pr9 = _vm.$v.product[_vm.productDataName]) === null || _vm$$v$product$_vm$pr9 === void 0 ? void 0 : (_vm$$v$product$_vm$pr10 = _vm$$v$product$_vm$pr9.initialPaymentBankAccount) === null || _vm$$v$product$_vm$pr10 === void 0 ? void 0 : _vm$$v$product$_vm$pr10.swift,
      "disabled": _vm.disableInitialPaymentSwiftCode
    },
    model: {
      value: _vm.product[_vm.productDataName].initialPaymentBankAccount.swift,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].initialPaymentBankAccount, "swift", $$v);
      },
      expression: "product[productDataName].initialPaymentBankAccount.swift"
    }
  }), _c('sygni-input', {
    attrs: {
      "label": 'BANK ACCOUNT NAME',
      "validation": (_vm$$v4 = _vm.$v) === null || _vm$$v4 === void 0 ? void 0 : (_vm$$v4$product$_vm$p = _vm$$v4.product[_vm.productDataName]) === null || _vm$$v4$product$_vm$p === void 0 ? void 0 : (_vm$$v4$product$_vm$p2 = _vm$$v4$product$_vm$p.initialPaymentBankAccount) === null || _vm$$v4$product$_vm$p2 === void 0 ? void 0 : _vm$$v4$product$_vm$p2.name
    },
    model: {
      value: _vm.product[_vm.productDataName].initialPaymentBankAccount.name,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].initialPaymentBankAccount, "name", $$v);
      },
      expression: "product[productDataName].initialPaymentBankAccount.name"
    }
  }), _c('sygni-input', {
    attrs: {
      "label": "OWNER"
    },
    model: {
      value: _vm.product[_vm.productDataName].initialPaymentBankAccount.owner,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].initialPaymentBankAccount, "owner", $$v);
      },
      expression: "product[productDataName].initialPaymentBankAccount.owner"
    }
  })], 1), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "content__bank-account"
  }, [_c('sygni-input', {
    attrs: {
      "displayCheck": _vm.principalPaymentBankAccountDisplayCheck,
      "label": 'PRINCIPAL REPAYMENT',
      "mask": _vm.principalPaymentBankAccountDisplayFormat,
      "validation": (_vm$$v$product$_vm$pr11 = _vm.$v.product[_vm.productDataName]) === null || _vm$$v$product$_vm$pr11 === void 0 ? void 0 : (_vm$$v$product$_vm$pr12 = _vm$$v$product$_vm$pr11.principalPaymentBankAccount) === null || _vm$$v$product$_vm$pr12 === void 0 ? void 0 : _vm$$v$product$_vm$pr12.account
    },
    on: {
      "input": _vm.handlePrincipalPaymentBankAccountKeyEvent,
      "blur": _vm.overwritePrincipalPaymentBankAccount
    },
    model: {
      value: _vm.product[_vm.productDataName].principalPaymentBankAccount.account,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].principalPaymentBankAccount, "account", $$v);
      },
      expression: "product[productDataName].principalPaymentBankAccount.account"
    }
  }), _c('sygni-select', {
    attrs: {
      "label": 'COUNTRY',
      "allowEmpty": false,
      "optionsLabel": "value",
      "options": _vm.globalSelects.countries,
      "searchable": true
    },
    model: {
      value: _vm.product[_vm.productDataName].principalPaymentBankAccount.country,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].principalPaymentBankAccount, "country", $$v);
      },
      expression: "product[productDataName].principalPaymentBankAccount.country"
    }
  })], 1), _c('sygni-input', {
    attrs: {
      "label": "SWIFT CODE",
      "validation": (_vm$$v$product$_vm$pr13 = _vm.$v.product[_vm.productDataName]) === null || _vm$$v$product$_vm$pr13 === void 0 ? void 0 : (_vm$$v$product$_vm$pr14 = _vm$$v$product$_vm$pr13.principalPaymentBankAccount) === null || _vm$$v$product$_vm$pr14 === void 0 ? void 0 : _vm$$v$product$_vm$pr14.swift,
      "disabled": _vm.disablePrincipalPaymentSwiftCode
    },
    model: {
      value: _vm.product[_vm.productDataName].principalPaymentBankAccount.swift,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].principalPaymentBankAccount, "swift", $$v);
      },
      expression: "product[productDataName].principalPaymentBankAccount.swift"
    }
  }), _c('sygni-input', {
    attrs: {
      "label": 'BANK ACCOUNT NAME',
      "validation": (_vm$$v5 = _vm.$v) === null || _vm$$v5 === void 0 ? void 0 : (_vm$$v5$product$_vm$p = _vm$$v5.product[_vm.productDataName]) === null || _vm$$v5$product$_vm$p === void 0 ? void 0 : (_vm$$v5$product$_vm$p2 = _vm$$v5$product$_vm$p.principalPaymentBankAccount) === null || _vm$$v5$product$_vm$p2 === void 0 ? void 0 : _vm$$v5$product$_vm$p2.name
    },
    model: {
      value: _vm.product[_vm.productDataName].principalPaymentBankAccount.name,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].principalPaymentBankAccount, "name", $$v);
      },
      expression: "product[productDataName].principalPaymentBankAccount.name"
    }
  }), _c('sygni-input', {
    attrs: {
      "label": "OWNER"
    },
    model: {
      value: _vm.product[_vm.productDataName].principalPaymentBankAccount.owner,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].principalPaymentBankAccount, "owner", $$v);
      },
      expression: "product[productDataName].principalPaymentBankAccount.owner"
    }
  })], 1), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "content__bank-account"
  }, [_c('sygni-input', {
    attrs: {
      "displayCheck": _vm.interestPaymentBankAccountDisplayCheck,
      "label": 'INTEREST REPAYMENT',
      "mask": _vm.interestPaymentBankAccountDisplayFormat,
      "validation": (_vm$$v$product$_vm$pr15 = _vm.$v.product[_vm.productDataName]) === null || _vm$$v$product$_vm$pr15 === void 0 ? void 0 : (_vm$$v$product$_vm$pr16 = _vm$$v$product$_vm$pr15.interestPaymentBankAccount) === null || _vm$$v$product$_vm$pr16 === void 0 ? void 0 : _vm$$v$product$_vm$pr16.account
    },
    on: {
      "input": _vm.handleInterestPaymentBankAccountKeyEvent,
      "blur": _vm.overwriteInterestPaymentBankAccount
    },
    model: {
      value: _vm.product[_vm.productDataName].interestPaymentBankAccount.account,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].interestPaymentBankAccount, "account", $$v);
      },
      expression: "product[productDataName].interestPaymentBankAccount.account"
    }
  }), _c('sygni-select', {
    attrs: {
      "label": 'COUNTRY',
      "allowEmpty": false,
      "optionsLabel": "value",
      "options": _vm.globalSelects.countries,
      "searchable": true
    },
    model: {
      value: _vm.product[_vm.productDataName].interestPaymentBankAccount.country,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].interestPaymentBankAccount, "country", $$v);
      },
      expression: "product[productDataName].interestPaymentBankAccount.country"
    }
  })], 1), _c('sygni-input', {
    attrs: {
      "label": "SWIFT CODE",
      "validation": (_vm$$v$product$_vm$pr17 = _vm.$v.product[_vm.productDataName]) === null || _vm$$v$product$_vm$pr17 === void 0 ? void 0 : (_vm$$v$product$_vm$pr18 = _vm$$v$product$_vm$pr17.interestPaymentBankAccount) === null || _vm$$v$product$_vm$pr18 === void 0 ? void 0 : _vm$$v$product$_vm$pr18.swift,
      "disabled": _vm.disableInterestPaymentSwiftCode
    },
    model: {
      value: _vm.product[_vm.productDataName].interestPaymentBankAccount.swift,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].interestPaymentBankAccount, "swift", $$v);
      },
      expression: "product[productDataName].interestPaymentBankAccount.swift"
    }
  }), _c('sygni-input', {
    attrs: {
      "label": 'BANK ACCOUNT NAME',
      "validation": (_vm$$v6 = _vm.$v) === null || _vm$$v6 === void 0 ? void 0 : (_vm$$v6$product$_vm$p = _vm$$v6.product[_vm.productDataName]) === null || _vm$$v6$product$_vm$p === void 0 ? void 0 : (_vm$$v6$product$_vm$p2 = _vm$$v6$product$_vm$p.interestPaymentBankAccount) === null || _vm$$v6$product$_vm$p2 === void 0 ? void 0 : _vm$$v6$product$_vm$p2.name
    },
    model: {
      value: _vm.product[_vm.productDataName].interestPaymentBankAccount.name,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].interestPaymentBankAccount, "name", $$v);
      },
      expression: "product[productDataName].interestPaymentBankAccount.name"
    }
  }), _c('sygni-input', {
    attrs: {
      "label": "OWNER"
    },
    model: {
      value: _vm.product[_vm.productDataName].interestPaymentBankAccount.owner,
      callback: function callback($$v) {
        _vm.$set(_vm.product[_vm.productDataName].interestPaymentBankAccount, "owner", $$v);
      },
      expression: "product[productDataName].interestPaymentBankAccount.owner"
    }
  })], 1), _c('div', {
    staticClass: "container"
  }, [[_c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.activeContextWalletsOptions,
      "clear-on-select": false,
      "label": 'DEBTOR',
      "value": (_vm$product$_vm$produ4 = _vm.product[_vm.productDataName]) === null || _vm$product$_vm$produ4 === void 0 ? void 0 : (_vm$product$_vm$produ5 = _vm$product$_vm$produ4.debtor) === null || _vm$product$_vm$produ5 === void 0 ? void 0 : _vm$product$_vm$produ5.account,
      "validation": (_vm$$v$product$_vm$pr19 = _vm.$v.product[_vm.productDataName]) === null || _vm$$v$product$_vm$pr19 === void 0 ? void 0 : (_vm$$v$product$_vm$pr20 = _vm$$v$product$_vm$pr19.debtor) === null || _vm$$v$product$_vm$pr20 === void 0 ? void 0 : _vm$$v$product$_vm$pr20.account,
      "selectOnlyOption": true
    },
    on: {
      "input": _vm.setDebtor
    }
  }), _c('label', {
    staticClass: "debtor-data mb-3"
  }, [_c('span', {
    staticClass: "debtor-data__title"
  }, [_vm._v(" " + _vm._s(_vm.$t('marketplace.debtor_account_name')) + ": ")]), _vm._v(" " + _vm._s(_vm.selectedBankAccountName(_vm.product[_vm.productDataName].debtor)) + " ")]), _c('label', {
    staticClass: "debtor-data mb-3"
  }, [_c('span', {
    staticClass: "debtor-data__title"
  }, [_vm._v(" " + _vm._s(_vm.$t('marketplace.debtor_account_currency')) + ": ")]), _vm._v(" " + _vm._s(_vm.selectedBankAccountCurrency(_vm.product[_vm.productDataName].debtor)) + " ")])]], 2)] : _vm._e(), _c('div', {
    staticClass: "description mt-4"
  }, [_vm._v(" Verify all entered data and continue the process ")]), _c('sygni-rect-button', {
    staticClass: "filled red mt-4",
    on: {
      "click": function click($event) {
        return _vm.goNext();
      }
    }
  }, [_vm._v(" Go to resume ")]), _c('div', {
    staticClass: "cancel-button",
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_vm._v("Cancel")])], 2)] : [_c('div', {
    staticClass: "content__group content__group--big"
  }, [_c('sygni-date-picker', {
    staticClass: "secondary",
    attrs: {
      "label": 'AGREEMENT DATE',
      "validation": _vm.$v.product.agreementDate
    },
    model: {
      value: _vm.product.agreementDate,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "agreementDate", $$v);
      },
      expression: "product.agreementDate"
    }
  }), _c('div', {
    staticClass: "investment-value-group"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'INVESTMENT VALUE',
      "validation": _vm.$v.product.investmentValue,
      "placeholder": "0,00",
      "disabled": ((_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : _vm$product4.type) === 'JOINT_STOCK_SHARES' ? true : false,
      "displayFormat": 'currency'
    },
    on: {
      "input": _vm.updateIssueValue,
      "blur": _vm.updateInstrumentCount
    },
    model: {
      value: _vm.product.investmentValue,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "investmentValue", $$v);
      },
      expression: "product.investmentValue"
    }
  }), _c('sygni-select', {
    attrs: {
      "options": _vm.usedCurrencies,
      "allowEmpty": false,
      "disabled": true,
      "validation": _vm.$v.product.currency,
      "clearable": false
    },
    model: {
      value: _vm.product.currency,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "currency", $$v);
      },
      expression: "product.currency"
    }
  })], 1), _c('sygni-checkbox', {
    staticClass: "secondary mb-3",
    attrs: {
      "label": "CLAT Tax"
    },
    model: {
      value: _vm.product.clatTax,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "clatTax", $$v);
      },
      expression: "product.clatTax"
    }
  }), _c('p', {
    staticClass: "title mb-4"
  }, [_vm._v(_vm._s(((_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : _vm$product5.type) === 'JOINT_STOCK_SHARES' ? 'Series of shares' : 'LLC Shares'))]), ((_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : _vm$product6.type) === 'JOINT_STOCK_SHARES' ? _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "container__group subscription-pool"
  }, [_c('p', {
    staticClass: "label"
  }, [_vm._v("SUBSCRIPTION POOL "), (_vm$product7 = _vm.product) !== null && _vm$product7 !== void 0 && (_vm$product7$data = _vm$product7.data) !== null && _vm$product7$data !== void 0 && (_vm$product7$data$ser = _vm$product7$data.seriesOfShares) !== null && _vm$product7$data$ser !== void 0 && _vm$product7$data$ser.name && (_vm$currentAvailableS = _vm.currentAvailableSubscriptionSlots) !== null && _vm$currentAvailableS !== void 0 && _vm$currentAvailableS.length ? _c('span', [_vm._v("("), _c('sygni-link-button', {
    attrs: {
      "type": "simple",
      "id": "subscriptionPoolTooltip"
    }
  }, [_vm._v("SEE AVAILABLE SLOTS")]), _vm._v(")")], 1) : _vm._e(), (_vm$product8 = _vm.product) !== null && _vm$product8 !== void 0 && (_vm$product8$data = _vm$product8.data) !== null && _vm$product8$data !== void 0 && (_vm$product8$data$ser = _vm$product8$data.seriesOfShares) !== null && _vm$product8$data$ser !== void 0 && _vm$product8$data$ser.name && !((_vm$currentAvailableS2 = _vm.currentAvailableSubscriptionSlots) !== null && _vm$currentAvailableS2 !== void 0 && _vm$currentAvailableS2.length) ? _c('span', {
    attrs: {
      "id": "subscriptionPoolTooltip"
    }
  }, [_vm._v("(NO SLOTS AVAILABLE)")]) : _vm._e()]), (_vm$product9 = _vm.product) !== null && _vm$product9 !== void 0 && (_vm$product9$data = _vm$product9.data) !== null && _vm$product9$data !== void 0 && (_vm$product9$data$ser = _vm$product9$data.seriesOfShares) !== null && _vm$product9$data$ser !== void 0 && _vm$product9$data$ser.name && (_vm$currentAvailableS3 = _vm.currentAvailableSubscriptionSlots) !== null && _vm$currentAvailableS3 !== void 0 && _vm$currentAvailableS3.length ? _c('b-tooltip', {
    attrs: {
      "boundary": "window",
      "customClass": "slots-tooltip",
      "placement": "top",
      "target": "subscriptionPoolTooltip",
      "triggers": "hover"
    }
  }, [_c('p', {
    staticClass: "label"
  }, [_vm._v("Available slots")]), _c('table', {
    staticClass: "slots-table"
  }, [_c('tbody', _vm._l(_vm.currentAvailableSubscriptionSlots, function (range, index) {
    return _c('tr', {
      key: "subscriptionSlot_".concat(index)
    }, [_c('td', [_vm._v("Slot " + _vm._s(index + 1) + ":")]), _c('td', [_vm._v(_vm._s(_vm._f("thousandSeparator")(range === null || range === void 0 ? void 0 : range.counterFrom)) + " - " + _vm._s(_vm._f("thousandSeparator")(range === null || range === void 0 ? void 0 : range.counterTo)) + " (" + _vm._s(_vm._f("thousandSeparator")(range === null || range === void 0 ? void 0 : range.instrumentCount)) + " instruments)")])]);
  }), 0)])]) : _vm._e(), _c('sygni-input', {
    attrs: {
      "value": _vm.subscriptionPool,
      "placeholder": _vm._f("numberFormat")(_vm.subscriptionPool, 0),
      "disabled": true,
      "displayFormat": "integer"
    }
  })], 1), _c('div', {
    staticClass: "container__group"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.seriesOfSharesOptions,
      "searchable": true,
      "value": (_vm$product$data = _vm.product.data) === null || _vm$product$data === void 0 ? void 0 : (_vm$product$data$seri = _vm$product$data.seriesOfShares) === null || _vm$product$data$seri === void 0 ? void 0 : _vm$product$data$seri.name,
      "validation": (_vm$$v7 = _vm.$v) === null || _vm$$v7 === void 0 ? void 0 : (_vm$$v7$product = _vm$$v7.product) === null || _vm$$v7$product === void 0 ? void 0 : (_vm$$v7$product$data = _vm$$v7$product.data) === null || _vm$$v7$product$data === void 0 ? void 0 : (_vm$$v7$product$data$ = _vm$$v7$product$data.seriesOfShares) === null || _vm$$v7$product$data$ === void 0 ? void 0 : _vm$$v7$product$data$.name,
      "label": "NAME"
    },
    on: {
      "input": function input($event) {
        return _vm.setSeriesName($event);
      }
    }
  }), _c('sygni-input', {
    attrs: {
      "label": "INSTRUMENT COUNT",
      "validation": (_vm$$v8 = _vm.$v) === null || _vm$$v8 === void 0 ? void 0 : (_vm$$v8$product = _vm$$v8.product) === null || _vm$$v8$product === void 0 ? void 0 : (_vm$$v8$product$data = _vm$$v8$product.data) === null || _vm$$v8$product$data === void 0 ? void 0 : (_vm$$v8$product$data$ = _vm$$v8$product$data.seriesOfShares) === null || _vm$$v8$product$data$ === void 0 ? void 0 : _vm$$v8$product$data$.instrumentCount,
      "disabled": _vm.disableSeriesOfSharesAmounts,
      "displayFormat": "integer"
    },
    on: {
      "blur": _vm.handleInstrumentCountBlur
    },
    model: {
      value: _vm.product.data.seriesOfShares.instrumentCount,
      callback: function callback($$v) {
        _vm.$set(_vm.product.data.seriesOfShares, "instrumentCount", $$v);
      },
      expression: "product.data.seriesOfShares.instrumentCount"
    }
  })], 1), _c('div', {
    staticClass: "container__group"
  }, [_c('sygni-input', {
    attrs: {
      "label": "COUNTER FROM",
      "validation": (_vm$$v9 = _vm.$v) === null || _vm$$v9 === void 0 ? void 0 : (_vm$$v9$product = _vm$$v9.product) === null || _vm$$v9$product === void 0 ? void 0 : (_vm$$v9$product$data = _vm$$v9$product.data) === null || _vm$$v9$product$data === void 0 ? void 0 : (_vm$$v9$product$data$ = _vm$$v9$product$data.seriesOfShares) === null || _vm$$v9$product$data$ === void 0 ? void 0 : _vm$$v9$product$data$.counterFrom,
      "disabled": _vm.disableSeriesOfSharesAmounts,
      "displayFormat": "integer"
    },
    on: {
      "blur": _vm.handleCounterFromBlur
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function fn() {
        return [_vm._v("Counter from out of range of available slots")];
      },
      proxy: true
    }], null, false, 1504229784),
    model: {
      value: _vm.product.data.seriesOfShares.counterFrom,
      callback: function callback($$v) {
        _vm.$set(_vm.product.data.seriesOfShares, "counterFrom", $$v);
      },
      expression: "product.data.seriesOfShares.counterFrom"
    }
  }), _c('sygni-input', {
    attrs: {
      "label": "COUNTER TO",
      "value": (_vm$product$data2 = _vm.product.data) === null || _vm$product$data2 === void 0 ? void 0 : (_vm$product$data2$ser = _vm$product$data2.seriesOfShares) === null || _vm$product$data2$ser === void 0 ? void 0 : _vm$product$data2$ser.counterTo,
      "validation": (_vm$$v10 = _vm.$v) === null || _vm$$v10 === void 0 ? void 0 : (_vm$$v10$product = _vm$$v10.product) === null || _vm$$v10$product === void 0 ? void 0 : (_vm$$v10$product$data = _vm$$v10$product.data) === null || _vm$$v10$product$data === void 0 ? void 0 : (_vm$$v10$product$data2 = _vm$$v10$product$data.seriesOfShares) === null || _vm$$v10$product$data2 === void 0 ? void 0 : _vm$$v10$product$data2.counterTo,
      "disabled": true,
      "displayFormat": "integer"
    }
  })], 1), _c('div', {
    staticClass: "container__group"
  }, [_c('sygni-input', {
    attrs: {
      "label": "NOMINAL VALUE",
      "value": (_vm$product$data3 = _vm.product.data) === null || _vm$product$data3 === void 0 ? void 0 : (_vm$product$data3$ser = _vm$product$data3.seriesOfShares) === null || _vm$product$data3$ser === void 0 ? void 0 : _vm$product$data3$ser.nominalValue,
      "validation": (_vm$$v11 = _vm.$v) === null || _vm$$v11 === void 0 ? void 0 : (_vm$$v11$product = _vm$$v11.product) === null || _vm$$v11$product === void 0 ? void 0 : (_vm$$v11$product$data = _vm$$v11$product.data) === null || _vm$$v11$product$data === void 0 ? void 0 : (_vm$$v11$product$data2 = _vm$$v11$product$data.seriesOfShares) === null || _vm$$v11$product$data2 === void 0 ? void 0 : _vm$$v11$product$data2.nominalValue,
      "disabled": true,
      "displayFormat": "number"
    }
  }), _c('sygni-input', {
    attrs: {
      "label": "ISSUE VALUE",
      "value": (_vm$product$data4 = _vm.product.data) === null || _vm$product$data4 === void 0 ? void 0 : (_vm$product$data4$ser = _vm$product$data4.seriesOfShares) === null || _vm$product$data4$ser === void 0 ? void 0 : _vm$product$data4$ser.issueValue,
      "validation": (_vm$$v12 = _vm.$v) === null || _vm$$v12 === void 0 ? void 0 : (_vm$$v12$product = _vm$$v12.product) === null || _vm$$v12$product === void 0 ? void 0 : (_vm$$v12$product$data = _vm$$v12$product.data) === null || _vm$$v12$product$data === void 0 ? void 0 : (_vm$$v12$product$data2 = _vm$$v12$product$data.seriesOfShares) === null || _vm$$v12$product$data2 === void 0 ? void 0 : _vm$$v12$product$data2.issueValue,
      "disabled": true,
      "displayFormat": "number"
    }
  })], 1)]) : _c('div', {
    staticClass: "container"
  }, [_c('sygni-input', {
    attrs: {
      "label": "INSTRUMENT COUNT",
      "validation": (_vm$$v13 = _vm.$v) === null || _vm$$v13 === void 0 ? void 0 : (_vm$$v13$product = _vm$$v13.product) === null || _vm$$v13$product === void 0 ? void 0 : (_vm$$v13$product$data = _vm$$v13$product.data) === null || _vm$$v13$product$data === void 0 ? void 0 : (_vm$$v13$product$data2 = _vm$$v13$product$data.llcShares) === null || _vm$$v13$product$data2 === void 0 ? void 0 : _vm$$v13$product$data2.instrumentCount,
      "displayFormat": "integer"
    },
    on: {
      "blur": _vm.updateInvestmentValue,
      "input": _vm.updateIssueValue
    },
    model: {
      value: _vm.product.data.llcShares.instrumentCount,
      callback: function callback($$v) {
        _vm.$set(_vm.product.data.llcShares, "instrumentCount", $$v);
      },
      expression: "product.data.llcShares.instrumentCount"
    }
  }), _c('div', {
    staticClass: "container__group"
  }, [_c('sygni-input', {
    attrs: {
      "label": "NOMINAL VALUE",
      "value": (_vm$product$data5 = _vm.product.data) === null || _vm$product$data5 === void 0 ? void 0 : (_vm$product$data5$llc = _vm$product$data5.llcShares) === null || _vm$product$data5$llc === void 0 ? void 0 : _vm$product$data5$llc.nominalValue,
      "validation": (_vm$$v14 = _vm.$v) === null || _vm$$v14 === void 0 ? void 0 : (_vm$$v14$product = _vm$$v14.product) === null || _vm$$v14$product === void 0 ? void 0 : (_vm$$v14$product$data = _vm$$v14$product.data) === null || _vm$$v14$product$data === void 0 ? void 0 : (_vm$$v14$product$data2 = _vm$$v14$product$data.llcShares) === null || _vm$$v14$product$data2 === void 0 ? void 0 : _vm$$v14$product$data2.nominalValue,
      "disabled": true,
      "displayFormat": "number"
    }
  }), _c('sygni-input', {
    attrs: {
      "label": "ISSUE VALUE",
      "value": (_vm$product$data6 = _vm.product.data) === null || _vm$product$data6 === void 0 ? void 0 : (_vm$product$data6$llc = _vm$product$data6.llcShares) === null || _vm$product$data6$llc === void 0 ? void 0 : _vm$product$data6$llc.issueValue,
      "validation": (_vm$$v15 = _vm.$v) === null || _vm$$v15 === void 0 ? void 0 : (_vm$$v15$product = _vm$$v15.product) === null || _vm$$v15$product === void 0 ? void 0 : (_vm$$v15$product$data = _vm$$v15$product.data) === null || _vm$$v15$product$data === void 0 ? void 0 : (_vm$$v15$product$data2 = _vm$$v15$product$data.llcShares) === null || _vm$$v15$product$data2 === void 0 ? void 0 : _vm$$v15$product$data2.issueValue,
      "disabled": true,
      "displayFormat": "number"
    }
  })], 1), _vm.differentIssueValue ? _c('sygni-info-box', {
    staticClass: "mb-4",
    attrs: {
      "size": "small"
    }
  }, [_vm._v("The issue value is different from the subscription issue value.")]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "content__group content__group--small"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Details ")]), _c('sygni-input', {
    attrs: {
      "label": 'PRODUCT TYPE',
      "value": _vm.product.type === 'JOINT_STOCK_SHARES' ? 'Joint Stock Share' : 'LLC Share',
      "disabled": true,
      "validation": _vm.$v.product.type
    }
  }), _vm.isMarketplaceAdmin || _vm.isMarketplaceHead ? _c('sygni-input', {
    attrs: {
      "label": 'PRODUCT CODE',
      "disabled": _vm.disableProductCode,
      "validation": _vm.$v.product.code
    },
    model: {
      value: _vm.product.code,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "code", $$v);
      },
      expression: "product.code"
    }
  }) : _vm._e(), _c('sygni-checkbox', {
    staticClass: "secondary autogenerate-checkbox",
    attrs: {
      "label": "AUTOGENERATE PRODUCT CODE"
    },
    model: {
      value: _vm.product.autogeneratedCode,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "autogeneratedCode", $$v);
      },
      expression: "product.autogeneratedCode"
    }
  }), _vm.isMarketplaceAdmin || _vm.isMarketplaceHead ? _c('sygni-input', {
    attrs: {
      "label": 'PRODUCT NAME',
      "validation": _vm.$v.product.name
    },
    model: {
      value: _vm.product.name,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "name", $$v);
      },
      expression: "product.name"
    }
  }) : _vm._e(), _c('sygni-select', {
    attrs: {
      "options": _vm.attachmentOptions,
      "label": "AGREEMENT TEMPLATE",
      "selectOnlyOption": true,
      "validation": _vm.$v.product.agreementTemplateId
    },
    model: {
      value: _vm.product.agreementTemplateId,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "agreementTemplateId", $$v);
      },
      expression: "product.agreementTemplateId"
    }
  }), _c('div', {
    staticClass: "description mt-4"
  }, [_vm._v(" Verify all entered data and continue the process ")]), _c('sygni-rect-button', {
    staticClass: "filled red mt-4",
    on: {
      "click": function click($event) {
        return _vm.goNext();
      }
    }
  }, [_vm._v(" Go to resume ")]), _c('div', {
    staticClass: "cancel-button",
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_vm._v("Cancel")])], 1)]], 2)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openedTab == 'agreement',
      expression: "openedTab == 'agreement'"
    }],
    staticClass: "add-new-investor-form__body"
  }, [_c('div', {
    staticClass: "content content--no-padding"
  }, [_c('editor-component', {
    attrs: {
      "content": (_vm$agreementTemplate = _vm.agreementTemplate) === null || _vm$agreementTemplate === void 0 ? void 0 : _vm$agreementTemplate.content,
      "editable": false,
      "simple": true,
      "issuer": _vm.issuer,
      "legalEntityInvestorData": _vm.legalEntityInvestorData,
      "investor": _vm.investor,
      "subscription": _vm.subscription,
      "legalEntityData": _vm.legalEntityData,
      "product": _vm.product
    }
  })], 1), _c('attachments', {
    attrs: {
      "attachments": (_vm$agreementTemplate2 = _vm.agreementTemplate) === null || _vm$agreementTemplate2 === void 0 ? void 0 : _vm$agreementTemplate2.attachments,
      "editMode": "preview"
    },
    on: {
      "openAttachment": _vm.openAttachmentPreview
    }
  })], 1), _c('attachment-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showAttachmentPreviewModal,
      expression: "showAttachmentPreviewModal"
    }],
    attrs: {
      "attachment": _vm.selectedAttachment,
      "issuer": _vm.issuer,
      "investor": _vm.investor,
      "product": _vm.product,
      "investorDetails": _vm.investorDetails,
      "legalEntityInvestorData": _vm.legalEntityInvestorData
    },
    on: {
      "close": _vm.closeAttachmentPreviewModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }